import { Text, Image } from '@geist-ui/react'
import { Carousel } from '../Carousel'
import styles from './Highlights.module.css'
import { ProductImage } from '../ProductImage'
import { landingPage } from '../../_landingPage'

const Highlights = () => {
  return (
    <>
      <div className={styles.conservHighlights}>
        {/* ConServ product images */}
        <Carousel>
          {landingPage.productImages.map((imageSource) => (
            <ProductImage imageSource={imageSource} />
          ))}
        </Carousel>

        <div className={styles.problemContainer}>
          <div className={styles.problem}>
            <a
              href="https://www.flaticon.com/free-icons/contamination"
              target="_blank"
              rel="noreferrer"
              title="contamination icons"
            >
              <Image
                width="100px"
                height="100px"
                src="/assets/highlights/fish-and-plastic.png"
                loading="lazy"
              />
            </a>

            <Text p className={styles.problemInfo}>
              Plastic is estimated to outweigh the fish in the sea by 2050.
            </Text>
          </div>

          <div className={styles.problem}>
            <a
              href="https://www.flaticon.com/free-icons/wood"
              title="wood icons"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                width="100px"
                height="100px"
                src="/assets/highlights/trees.png"
                loading="lazy"
              />
            </a>

            <Text p className={styles.problemInfo}>
              Paper packaging lead to 8Million trees
              being cut every day. The destroyed ecosystem is not growing as
              fast as we harvest!
            </Text>
          </div>

          <div className={styles.problem}>
            <a
              href="https://www.flaticon.com/free-icons/agriculture"
              title="agriculture icons"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                width="100px"
                height="100px"
                src="/assets/highlights/crops.png"
                loading="lazy"
              />
            </a>

            <Text p className={styles.problemInfo}>
              Packaging from agricultural waste
              requires lot of chemicals to make them functional.
            </Text>
          </div>
        </div>

        {/* About us section */}

        <Text p b className={styles.conservSolutionHeading}>
          THE CONSERV SOLUTION
        </Text>
        <div className={styles.conservSolution}>
          <Image
            style={{ borderRadius: 20, objectFit: 'contain' }}
            width="260px"
            height="300px"
            src="/assets/highlights/conserv-solution.png"
            loading="lazy"
          />
          <Text p className={styles.solutionText}>
            <p>100% natural leaf-based</p>
            <p>No trees are cut! Fallen leaves are used</p>
            <p>0% chemicals added</p>
            <p>Biodegradable & Compostable</p>
            <p>Certified for Food Contact</p>
            <p>Microwave, oven and Freezer safe</p>
            <p>Resistive to hot liquids and greasy sauces naturally</p>
            <p>Reusable for gardens as fertilizer</p>
          </Text>
        </div>

        <div className={styles.conservTagline}>
          <Text className={styles.sustainableText} h1>
            Sustainable.
          </Text>
          <Text className={styles.consciousText} h1>
            Circular.
          </Text>
          <Text className={styles.futureText} h1>
            Future.
          </Text>
        </div>
      </div>
    </>
  )
}

export default Highlights
