import { Page } from '@geist-ui/react'
import { BurgerMenu } from '../../components/BurgerMenu'
import HowItsMade from '../../components/HowItsMade'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import styles from './HowItsMade.module.css'

const News = () => {
  return (
    <Page render="effect-seo" className={styles.pageContainer}>
      <Page.Header className={styles.header}>
        <Header />
      </Page.Header>
      <Page.Content className={styles.pageContent}>
        <BurgerMenu />
        <HowItsMade />
      </Page.Content>

      <Page.Footer
        style={{
          bottom: 'inherit',
          borderTop: 'solid 2.4px #eaeaea',
          position: 'sticky',
          background: 'white',
          zIndex: 2,
        }}
        className={styles.footerWrapper}
      >
        <Footer />
      </Page.Footer>
    </Page>
  )
}

export default News
