import { Text, Image } from '@geist-ui/react'
import styles from './Products.module.css'
import Button from '../Button'
import { ProductInfo } from '../../types'
import products from '../../_products'
import { ConservPageLink } from '../ConservPageLink'
import { Link } from 'react-router-dom'

const RenderProduct: React.FC<ProductInfo> = ({
  name = 'Super Bowl',
  description = 'Super bowl is best suited for serving salad and meals. Durable and suitable to serve hot and cold foods.',
  code = 'GWU78RM',
  dimensions = '15 x 15 cm, 18 x 18 cm, 24 x 24 cm',
  packaging = '1 - 2 weeks',
  productImage = '/assets/products/conserv.bowl.svg',
  id,
}) => {
  return (
    <>
      <div className={styles.product}>
        <Text p b className={styles.productName}>
          {name}
        </Text>

        <Image width="12" height="12" src={productImage} />

        <Text p className={styles.productDescription}>
          {description}
        </Text>

        <div className={styles.productInfo}>
          <div className={styles.productDetail}>
            <Text p className={styles.productAttribute}>
              Code
            </Text>
            <Text p className={styles.productAttributeValue}>
              {code}
            </Text>
          </div>
          <div className={styles.productDetail}>
            <Text p className={styles.productAttribute}>
              Dimension
            </Text>
            <Text p className={styles.productAttributeValue}>
              {dimensions}
            </Text>
          </div>
          <div className={styles.productDetail}>
            <Text p className={styles.productAttribute}>
              Packaging
            </Text>
            <Text p className={styles.productAttributeValue}>
              {packaging}
            </Text>
          </div>
        </div>

        <Link to={`/products/${id}`} style={{ textDecoration: 'none' }}>
          <Button text="Product Description" />
        </Link>
      </div>
    </>
  )
}

const Products: React.FC<{ isHomePage?: boolean }> = ({
  isHomePage = true,
}) => {
  const allProducts = Object.keys(products)
  const top4ProductsForHomePage = allProducts
    .reverse()
    .filter((productKey) => products[productKey].flagship)
    .slice(0, 4)
  const productsToBeRendered = isHomePage
    ? top4ProductsForHomePage
    : allProducts
  return (
    <div className={styles.productContainer} id="products">
      <div className={styles.products}>
        {productsToBeRendered.map((product) => {
          const {
            code,
            name,
            description,
            dimensions,
            packaging,
            ogImage,
            id,
          } = products[product]
          return (
            <RenderProduct
              key={code}
              name={name}
              description={description}
              code={code}
              dimensions={dimensions}
              packaging={packaging}
              productImage={ogImage.home}
              id={id}
            />
          )
        })}
      </div>

      {isHomePage ? (
        <Link to={`/all-products`} style={{ textDecoration: 'none' }}>
          <ConservPageLink text="See all products" />{' '}
        </Link>
      ) : null}
    </div>
  )
}

export default Products
