import { Button as GeistButton } from '@geist-ui/react'
import styles from './Button.module.css'

const Button: React.FC<{
  text: string
  onClick?: () => void
}> = ({ text, onClick = () => {} }) => {
  return (
    <GeistButton
      type="secondary"
      ghost
      scale={0.85}
      //       width="30%"
      className={styles.buttonText}
      onClick={onClick}
    >
      {text}
    </GeistButton>
  )
}

export default Button
