export const submitContact = async (data) => {
  const response = await fetch('/api/mail', {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      accept: 'application/json',
    },
    body: JSON.stringify(data),
  })

  return response
}
