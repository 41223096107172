const products = {
  'yelai-800ml-bowl': {
    id: 'yelai-800ml-bowl',
    name: '800ml bowl',
    description:
      'YELAI 800ml rectangle bowl serves best for catering purpose, suitable for both hot and Cold foods.',
    code: 'YELAI800',
    dimensions: '200mm (L) x 140mm (B) x 40mm (H)',
    packaging: '300pcs/carton',
    flagship: true,
    ogImage: {
      home: '/assets/products/800ml.jpg',
      productImages: [
        '/assets/products/800ml.jpg',
      ],
    },
    productFeatures:
      'YELAI Bowls are handpicked fallen Areca leaves from farm. Final shape is achieved by heat pressing technique, which makes our manufacturing process 100% chemical and additives free. Each bowl looks unique with natural texture. YELAI Bowls are Bio-degradable, Heat resistant, Water resistant, Oil resistant, Microwave-friendly, Oven-friendly and Freezer-friendly. All the above features makes the YELAI Bowl as Superior, Sustainable and Environmental conscious solution.',
    data: [
      { property: 'Material', description: 'Areca leaf' },
      { property: 'Volume', description: '800ml' },
      { property: 'Dimension', description: '200mm (L) x 140mm (B) x 40mm (H)' },
      { property: 'Approved for Food contact', description: 'Yes' },
      { property: 'Packaging (300pcs/carton)', description: '25pcs/pack, 12 pack/carton' },
      { property: 'Microwave safe', description: 'Yes' },
      { property: 'Oven safe', description: 'Yes' },
      { property: 'Freezer safe', description: 'Yes' },
      { property: 'Resistant to Water', description: 'Yes' },
      { property: 'Resistant to oil', description: 'Yes' },
      { property: 'Resistant to heat', description: 'Yes' },
    ],
    downloadableContent: '/assets/pdf/sample-pdf.pdf',
  },
  'yelai-90ml-bowl': {
    id: 'yelai-90ml-bowl',
    name: '90ml bowl',
    description:
      'YELAI 90ml round bowl serves multi purpose, as sauce bowl and also as Ice cream bowl.',
    code: 'YELAI90',
    dimensions: '100mm (⌀)x 25mm (H)',
    packaging: '600pcs/carton',
    flagship: true,
    ogImage: {
      home: '/assets/products/bowl-90-1.jpg',
      productImages: [
        '/assets/products/bowl-90-2.jpg',
        '/assets/products/bowl-90-1.jpg',
      ],
    },
    productFeatures:
      'YELAI Bowls are handpicked fallen Areca leaves from farm. Final shape is achieved by heat pressing technique, which makes our manufacturing process 100% chemical and additives free. Each bowl looks unique with natural texture. YELAI Bowls are Bio-degradable, Heat resistant, Water resistant, Oil resistant, Microwave-friendly, Oven-friendly and Freezer-friendly. All the above features makes the YELAI Bowl as Superior, Sustainable and Environmental conscious solution.',
    data: [
      { property: 'Material', description: 'Areca leaf' },
      { property: 'Volume', description: '90ml' },
      { property: 'Dimension', description: '100mm (⌀)x 25mm (H)' },
      { property: 'Approved for Food contact', description: 'Yes' },
      { property: 'Packaging (600pcs/carton)', description: '50pcs/pack, 12 pack/carton' },
      { property: 'Microwave safe', description: 'Yes' },
      { property: 'Oven safe', description: 'Yes' },
      { property: 'Freezer safe', description: 'Yes' },
      { property: 'Resistant to Water', description: 'Yes' },
      { property: 'Resistant to oil', description: 'Yes' },
      { property: 'Resistant to heat', description: 'Yes' },
    ],
    downloadableContent: '/assets/pdf/sample-pdf.pdf',
  },
  'yelai-250ml-bowl': {
    id: 'yelai-250ml-bowl',
    name: '250ml bowl',
    description:
      'YELAI 250ml round bowl serves as salad bowl, sauce bowl and Ice cream bowl.',
    code: 'YELAI250',
    dimensions: '140mm (⌀) x 40mm (H)',
    packaging: '600pcs/carton',
    flagship: true,
    ogImage: {
      home: '/assets/products/bowl-250-1.jpg',
      productImages: [
        '/assets/products/bowl-250-2.jpg',
        '/assets/products/bowl-250-1.jpg',
      ],
    },
    productFeatures:
      'YELAI Bowls are handpicked fallen Areca leaves from farm. Final shape is achieved by heat pressing technique, which makes our manufacturing process 100% chemical and additives free. Each bowl looks unique with natural texture. YELAI Bowls are Bio-degradable, Heat resistant, Water resistant, Oil resistant, Microwave-friendly, Oven-friendly and Freezer-friendly. All the above features makes the YELAI Bowl as Superior, Sustainable and Environmental conscious solution.',
    data: [
      { property: 'Material', description: 'Areca leaf' },
      { property: 'Volume', description: '250ml' },
      { property: 'Dimension', description: '140mm (⌀) x 40mm (H)' },
      { property: 'Approved for Food contact', description: 'Yes' },
      { property: 'Packaging (600pcs/carton)', description: '50pcs/pack, 12 pack/carton' },
      { property: 'Microwave safe', description: 'Yes' },
      { property: 'Oven safe', description: 'Yes' },
      { property: 'Freezer safe', description: 'Yes' },
      { property: 'Resistant to Water', description: 'Yes' },
      { property: 'Resistant to oil', description: 'Yes' },
      { property: 'Resistant to heat', description: 'Yes' },
    ],
    downloadableContent: '/assets/pdf/sample-pdf.pdf',
  },
  'yelai-350ml-container': {
    id: 'yelai-350ml-container',
    name: 'YELAI 350ml (Bowl+Lid)',
    description:
      'A 350ml square YELAI container is suitable for salads and half portion of the meal.',
    code: 'YELAI350',
    dimensions: '145mm (L) x 145mm (B) x 60mm (H)',
    packaging: '300pcs/carton',
    flagship: true,
    ogImage: {
      home: '/assets/products/350ml_1.jpg',
      productImages: [
        '/assets/products/350ml_2.jpg',
        '/assets/products/350ml_1.jpg',
      ],
    },
    productFeatures:
      'YELAI Containers are handpicked fallen Areca leaves from farm. Final shape is achieved by heat pressing technique, which makes our manufacturing process 100% chemical free and additives free. Each bowl looks unique with natural texture. YELAI Containers are Bio-degradable, Heat resistant, Water resistant, Oil resistant, Microwave-friendly, Oven-friendly and Freezer-friendly. All the above features makes the YELAI Container as Superior, Sustainable and Environmental conscious solution for take-away.',
    data: [
      { property: 'Material', description: 'Areca leaf' },
      { property: 'Volume', description: '350ml' },
      { property: 'Dimension Bowl', description: '130mm (L) x 130mm (B) x 40mm (H)' },
      { property: 'Dimension Lid', description: '145mm (L) x 145mm (B) x 20mm (H)' },
      { property: 'Approved for Food contact', description: 'Yes' },
      { property: 'Packaging (300pcs/carton)', description: '25pcs/pack, 12 pack/carton. Bowl and lid are packed in separate carton' },
      { property: 'Microwave safe', description: 'Yes' },
      { property: 'Oven safe', description: 'Yes' },
      { property: 'Freezer safe', description: 'Yes' },
      { property: 'Resistant to Water', description: 'Yes' },
      { property: 'Resistant to oil', description: 'Yes' },
      { property: 'Resistant to heat', description: 'Yes' },
    ],
    downloadableContent: '/assets/pdf/sample-pdf.pdf',
  },
  'yelai-900ml-container': {
    id: 'yelai-900ml-container',
    name: 'YELAI 900ml (Bowl+Lid)',
    description:
      'A 900ml round YELAI bowl and lid clicks well and very much suitable for both hot and Cold takeaway foods.',
    code: 'YELAI900',
    dimensions: '190mm (⌀) x 65mm (H)',
    packaging: '300pcs/carton',
    flagship: true,
    ogImage: {
      home: '/assets/products/900ml_1.jpg',
      productImages: [
        '/assets/products/900ml_2.jpg',
        '/assets/products/900ml_1.jpg',
      ],
    },
    productFeatures:
      'YELAI Containers are handpicked fallen Areca leaves from farm. Final shape is achieved by heat pressing technique, which makes our manufacturing process 100% chemical free and additives free. Each bowl looks unique with natural texture. YELAI Containers are Bio-degradable, Heat resistant, Water resistant, Oil resistant, Microwave-friendly, Oven-friendly and Freezer-friendly. All the above features makes the YELAI Container as Superior, Sustainable and Environmental conscious solution for take-away.',
    data: [
      { property: 'Material', description: 'Areca leaf' },
      { property: 'Volume', description: '900ml' },
      { property: 'Dimension Bowl', description: '190mm (⌀) x 55mm (H)' },
      { property: 'Dimension Lid', description: '180mm (⌀) x 20mm (H)' },
      { property: 'Approved for Food contact', description: 'Yes' },
      { property: 'Packaging (300pcs/carton)', description: '25pcs/pack, 12 pack/carton. Bowl and lid are packed in separate carton' },
      { property: 'Microwave safe', description: 'Yes' },
      { property: 'Oven safe', description: 'Yes' },
      { property: 'Freezer safe', description: 'Yes' },
      { property: 'Resistant to Water', description: 'Yes' },
      { property: 'Resistant to oil', description: 'Yes' },
      { property: 'Resistant to heat', description: 'Yes' },
    ],
    downloadableContent: '/assets/pdf/sample-pdf.pdf',
  },
}
export default products
