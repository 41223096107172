import { Text, Image } from '@geist-ui/react'
import { Linkedin, Github } from '@geist-ui/icons'
import { Link } from 'react-router-dom'
import styles from './Footer.module.css'

const Footer = () => {
  return (
    <div>
      <div className={styles.footerContainer}>
        <div className={styles.footerElement}>
          <div className={styles.footerImage}>
            <Link to={`/`} style={{ textDecoration: 'none' }}>
              <Image
                src="/images/conserv-figma-header-logo.svg"
                scale={1}
                width={10}
                height={1.8}
              />
            </Link>
          </div>
        </div>

        <div className={styles.footerElement}>
          <Text p className={styles.quickLinksTitle}>
            About us
          </Text>
          <Text p className={styles.footerText}>
            ConServ delivers Sustainable, Reusable and Environment conscious
            products for food packaging. Our products are 100% Natural and
            Chemical-free. We strive to enhance food packaging industry to be
            more sustainable. Together let us build the future.
          </Text>
        </div>

        <div className={styles.footerElement}>
          <Text p className={styles.quickLinksTitle}>
            Quick links
          </Text>
          <div className={styles.quickLinksContainer}>
            <Text p className={styles.quickLinks}>
              <Link to={`/aboutus`} style={{ textDecoration: 'none' }}>
                About us
              </Link>
            </Text>
            <Text p className={styles.quickLinks}>
              <Link to={`/all-products`} style={{ textDecoration: 'none' }}>
                Products
              </Link>
            </Text>
            <Text p className={styles.quickLinks}>
              <Link to={`/howitsmade`} style={{ textDecoration: 'none' }}>
                Process
              </Link>
            </Text>
            <Text p className={styles.quickLinks}>
              <Link to={`/customers`} style={{ textDecoration: 'none' }}>
                Customers
              </Link>
            </Text>
            <Text p className={styles.quickLinks}>
              <Link to={`/certifications`} style={{ textDecoration: 'none' }}>
                Certification
              </Link>
            </Text>
            <Text p className={styles.quickLinks}>
              <Link to={`/contact`} style={{ textDecoration: 'none' }}>
                Contact
              </Link>
            </Text>
          </div>
        </div>

        <div className={styles.footerElement}>
          <Text p className={styles.quickLinksTitle}>
            Contact
          </Text>
          <div className={styles.contactContainer}>
            <Text p className={styles.contactDetail}>
              ConServ Sverige AB
            </Text>
            <Text p className={styles.contactDetail}>
              Malmö, Sweden
            </Text>
            <Text p className={styles.contactDetail}>
              Email: info@conserv.se
            </Text>
            <Text p className={styles.contactDetail}>
              Org no: 559375-7452
            </Text>
          </div>
        </div>
      </div>
      <div className={styles.copyRightText}>
        <div>
          Copyright <b>©</b> ConServ. All rights reserved.
        </div>
        <div className={styles.developerDetails}>
          <span>Website developed by Gowtham Mohan</span>
          <div>
            <a
              target={'_blank'}
              rel="noreferrer"
              href="https://www.linkedin.com/in/gowtham-mohan/"
            >
              <Linkedin size={15} color="grey" />
            </a>
            <a
              target={'_blank'}
              href="https://github.com/Matwog"
              rel="noreferrer"
            >
              <Github size={15} color="grey" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
