export const posts = {
  'ConServ-future-of-sustainable-food-packaging': {
    id: 'ConServ-future-of-sustainable-food-packaging',
    name: 'ConServ - Future of Sustainable Food Packaging',
    author: 'Abinaya Krishnaraja',
    date: 'Oct 21st 2022',
    ogImage: '/assets/news/ConServ_Logo.png',
    content:
      '<p>ConServ was founded in 2022 in Malmö by the couple <b>Abi & Adhi</b>. Just like most working people who eat lunches out at restaurants almost every day, we explored the rich culinary culture of Skåne. The creativity that goes into the fusion of different cuisines is incomparable. Not only are the food prepared with sustainability in mind but the packaging for take-aways is also consciously chosen. We were amazed by the different varieties of eco-friendly take-away boxes used by the restaurants as alternatives to plastic. Our curiosity about how and what they were made of, led us to some shocking revelations! The base material in these food containers are naturally derived from plants, trees or agricultural waste. This is good news! However, we found that there are a lot of chemicals that need to be added to manufacture the final product. The sad news doen’t end there. These chemicals can accumulate in our body over time and lead to several health risks in the long-run. When we realized that we were consuming these chemicals every day, we were devastated. We strongly believe that nature always provides for our needs, and we just need to seek.<p><p>The fruitful result of our pursuit is <b>ConServ</b>.<p> <p>Our leaf-based food containers and tableware are 100% natural and has zero chemical additives. Each piece is a unique art designed by nature itself. Truly sustainable, natural & minimalistic living!<p>',
  },
}
