import { Menu, X } from '@geist-ui/icons'
import { useBurgerMenu } from '../../providers/AppProvider'
import styles from './Header.module.css'

const BurgerIcon = () => {
  const { showBurgerMenu, toggleBurgerMenuVisibility } = useBurgerMenu()
  return (
    <>
      {showBurgerMenu ? (
        <X
          className={styles.burgerMenu}
          color="#4C102A"
          width={2}
          height={2}
          onClick={() => toggleBurgerMenuVisibility()}
        />
      ) : (
        <Menu
          className={styles.burgerMenu}
          color="#4C102A"
          width={2}
          height={2}
          onClick={() => toggleBurgerMenuVisibility()}
        />
      )}
    </>
  )
}

export default BurgerIcon
