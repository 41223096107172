import { Text, Image, Grid } from '@geist-ui/react'
import styles from './Customers.module.css'

const Customers = () => {
  return (
    <div id="customers">
      <div className={styles.customers}>
        <Grid.Container
          className={styles.customerContainer}
          justify="center"
        >

          <Grid.Container
            className={styles.customerGridContainer}
            gap={0}
            justify="center"
            height="100px"
          >

            <Grid xs={6}>
              <a
              href="https://www.twoforks.se/"
              target="_blank"
              rel="noreferrer"
              >
              <Image
                width="80px"
                height="80px"
                src="/images/two_forks.png"
              />
              </a>
            </Grid>

            <Grid xs={6}>
              <a
              href="https://www.hummusson.se/"
              target="_blank"
              rel="noreferrer"
              >
              <Image
                width="80px"
                height="80px"
                src="/images/hummusson.jpg"
              />
              </a>
            </Grid>

            <Grid xs={6}>
              <a
              href="https://www.matverkstaden.se/"
              target="_blank"
              rel="noreferrer"
              >
              <Image
                width="80px"
                height="80px"
                src="/images/matverkstaden.png"
              />
              </a>
            </Grid>
            </Grid.Container>

          <Grid.Container
            className={styles.customerGridContainer}
            gap={0}
            justify="center"
            height="100px"
          >
            <Grid xs={6}>
              <a
              href="https://www.facebook.com/urvarjord"
              target="_blank"
              rel="noreferrer"
              >
              <Image
                width="80px"
                height="80px"
                src="/images/ur_var_jord.jpg"
              />
              </a>
            </Grid>

            <Grid xs={6}>
              <a
              href="https://kold.co/"
              target="_blank"
              rel="noreferrer"
              >
              <Image
                width="80px"
                height="80px"
                src="/images/kold.png"
              />
              </a>
            </Grid>

            <Grid xs={6}>
              <a
              href="https://www.levelmalmo.se/"
              target="_blank"
              rel="noreferrer"
              >
              <Image
                width="80px"
                height="80px"
                src="/images/level.png"
              />
              </a>
            </Grid>
          </Grid.Container>
          
        </Grid.Container>
      </div>
    </div>
  )
}

export default Customers
