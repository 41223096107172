import { Text, Image, Grid } from '@geist-ui/react'
import styles from './HowItsMade.module.css'

const HowItsMade = () => {
  return (
    <div id="howitsmade">
      <div className={styles.howitsmade}>
        <div className={styles.conservSolution}>

          <Image
            className={styles.processImage}
            style={{ borderRadius: 20, objectFit: 'contain' }}
            src="/assets/howitsmade/workers_pic.jpg"
            loading="lazy"
          />

          <Text p className={styles.processText}>
            <p>
              We contribute to the economic development of marginalized
              communities through our business. Our leaves are sourced from
              Indian farmers for a fair price who get extra revenue from a
              material that would otherwise get wasted. The products are
              manufactured by rural Indian women supporting them financially.
            </p>

            <p>
              To realize the vision of ConServ, we have established a family run
              business including the manufacturing and design engineering
              facilities in India. We have full control over the entire supply
              chain starting from material sourcing to quality control and
              product delivery.
            </p>
          </Text>

          <Image
            className={styles.processImage}
            style={{ borderRadius: 20, objectFit: 'contain' }}
            src="/assets/howitsmade/conserv_howitsmade.jpg"
            loading="lazy"
          />

          <Text p className={styles.steps}>
            <p>
              Step 1: South India has Areca farms in abundance cultivated mainly
              for arecanuts consumed predominantly in India.
            </p>

            <p>
              Step 2: Areca trees like any other tree shed leaves seasonally
              every year. But unlike other leaves these have superpowers such as
              high resistance to heat and water.
            </p>

            <p>
              Step 3: Farmers collect the fallen leaves and let it sun dry for
              1-2 days. We will then buy the leaves from the farmers and store
              them in our facility.
            </p>

            <p>
              Step 4: During the manufacturing process, the leaves will be
              washed with fresh water and heat pressed to the desired shape.
              After that, the tableware will undergo quality control and be
              packed in carton boxes.
            </p>

            <p>
              Step 5: Leftovers after the manufacturing will be crushed and sent
              back to the farms as fertilizers for the trees.
            </p>
          </Text>
        </div>
      </div>
    </div>
  )
}

export default HowItsMade
