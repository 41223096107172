import { Modal as GeistModal, Image } from '@geist-ui/react'
import { Link } from 'react-router-dom'
import styles from './Modal.module.css'

const Modal = ({
  state,
  callback,
  title = '',
  content = '',
  loading = false,
}) => {
  return (
    <GeistModal visible={state} onClose={callback}>
      <GeistModal.Title>
        <Image width={4} height={4} src="/conservlogo.png" />
      </GeistModal.Title>
      <GeistModal.Subtitle>{title}</GeistModal.Subtitle>
      <GeistModal.Content className={styles.content}>
        <p>{content}</p>
      </GeistModal.Content>
      <Link to={`/`} style={{ textDecoration: 'none', display: 'flex' }}>
        <GeistModal.Action className={styles.button} onClick={callback}>
          OK
        </GeistModal.Action>
      </Link>
    </GeistModal>
  )
}

export default Modal
