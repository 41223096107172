import { Carousel as ReactResponsiveCarousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const Carousel = ({ children }) => {
  return (
    <div>
      <ReactResponsiveCarousel
        showArrows={false}
        showStatus={false}
        autoPlay
        infiniteLoop
        interval={8000}
        transitionTime={2000}
      >
        {children}
      </ReactResponsiveCarousel>
    </div>
  )
}

export default Carousel
