import { Divider as GeistDivider } from '@geist-ui/react'
import styles from './Divider.module.css'

const Divider: React.FC<{ title: string }> = ({ title }) => {
  return (
    <GeistDivider
      align="center"
      className={styles.productDivider}
      h={3}
      type="default"
    >
      {title}
    </GeistDivider>
  )
}

export default Divider
