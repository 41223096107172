import { Text, Image } from '@geist-ui/react'
import styles from './AboutUs.module.css'
import { Mail, PhoneCall } from '@geist-ui/icons'
import ContactCard from './ContactCard'

const AboutUs = () => {
  return (
    <div id="aboutus">
      <div className={styles.aboutus}>
        <div>
          <Image
            style={{
              objectFit: 'cover',
              borderRadius: '50%',
              width: '250px',
              height: '250px',
            }}
            width="260px"
            height="260px"
            src="/assets/aboutus/adhi_abi_pic.jpg"
            loading="lazy"
          />
          <Text p className={styles.aboutusText}>
            <p>
              We are Abinaya and Adhithiyan, partners in life and in business.
              Abi has PhD in Nanotechnology and Adhi is a Software Development
              Engineer. We originally come from India - the land that sources
              the raw material for our sustainable food packaging. Both of us
              are engineers who met each other in Germany during our Masters
              studies.
            </p>

            <p>
              Coming from families with business in textile and industrial
              machinery, both of us have had the entrepreneurial spirit engraved
              in us. When we understood the demands and the scope of
              possibilities in the food packaging industry, we couldn't stop
              ourselves but to establish a company that serves not only our
              entrepreneurial spirit but also helps better the environment and
              the society.
            </p>

            <p>
              The business is family-run including the manufacturing and design
              engineering facilities in India. This gives us full control over
              the entire supply chain starting from material sourcing to quality
              control and product delivery. We strive to enhance food packaging
              industry to be more sustainable. Together let us build the future.
            </p>
          </Text>

          <div className={styles.contact}>
            <ContactCard
              name="Adhithiyan Pandian"
              mail="adhi@conserv.se"
              phone="+46709677410"
            />
            <ContactCard
              name="Abinaya Krishnaraja"
              mail="abinaya@conserv.se"
              phone="+46790483711"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
