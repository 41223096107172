import { Text, Spacer } from '@geist-ui/react'
import styles from './BurgerMenu.module.css'

import { InPageNavigation } from '../../helpers'
import { useBurgerMenu } from '../../providers/AppProvider'
import { Link } from 'react-router-dom'

const BurgerMenu = () => {
  const { showBurgerMenu } = useBurgerMenu()

  return (
    <>
      {showBurgerMenu ? (
        <div className={styles.burgerMenu}>
          <Text p className={styles.headerItems}>
            <Link to={`/aboutus`} style={{ textDecoration: 'none' }}>
              About us
            </Link>
          </Text>
          <Spacer h={1.5} />
          <Text p className={styles.headerItems}>
            <Link to={`/all-products`} style={{ textDecoration: 'none' }}>
              Products
            </Link>
          </Text>
          <Spacer h={1.5} />
          <Text p className={styles.headerItems}>
            <Link to={`/howitsmade`} style={{ textDecoration: 'none' }}>
              Process
            </Link>
          </Text>
          <Spacer h={1.5} />
          <Text p className={styles.headerItems}>
            <Link to={`/customers`} style={{ textDecoration: 'none' }}>
              Customers
            </Link>
          </Text>
          <Spacer h={1.5} />
          <Text p className={styles.headerItems}>
            <Link to={`/certifications`} style={{ textDecoration: 'none' }}>
              Certification
            </Link>
          </Text>
          <Spacer h={1.5} />
          <Text p className={styles.headerItems}>
            <Link to={`/contact`} style={{ textDecoration: 'none' }}>
              Contact
            </Link>
          </Text>
        </div>
      ) : null}
    </>
  )
}

export default BurgerMenu
