import { Card, Text, Divider } from '@geist-ui/react'
import { Link } from 'react-router-dom'
import Button from '../Button'
import { ConservPageLink } from '../ConservPageLink'
import styles from './News.module.css'
import { NewsMetaData } from './types'
import { posts } from '../../_posts/posts'

const RenderNews: React.FC<NewsMetaData> = ({
  date = 'Jul. 8th 2022',
  title = 'Why choose sustainable solutions',
  id = 'why-choose-sustainable-solutions',
}) => {
  return (
    <div className={styles.blogCardWrapper}>
      <Card shadow type="default" className={styles.blogCard}>
        <div className={styles.blogContainer}>
          <div>
            <Text className={styles.blogCreationDate} my={0}>
              {date}
            </Text>
            <Text h4 my={0} className={styles.blogTitle}>
              {title}
            </Text>
          </div>
          <Button text="Read more" />
        </div>
      </Card>
    </div>
  )
}

const News: React.FC<{ isHomePage?: boolean }> = ({ isHomePage = true }) => {
  const allPosts = Object.keys(posts).reverse()
  const top4PostsForHomePage = allPosts.slice(0, 4)

  const postsToBeRendered = isHomePage ? top4PostsForHomePage : allPosts
  return (
    <div id="news">
      <div className={styles.blog}>
        {postsToBeRendered.map((postKey) => {
          const post = posts[postKey]
          return (
            <Link to={`/news/${post.id}`} style={{ textDecoration: 'none' }}>
              <RenderNews
                key={post.id}
                date={post.date}
                title={post.name}
                id={post.id}
              />
            </Link>
          )
        })}
      </div>

      {isHomePage ? (
        <Link to={`/all-news`} style={{ textDecoration: 'none' }}>
          <ConservPageLink text="See all news" />{' '}
        </Link>
      ) : null}
    </div>
  )
}

export default News
