import { Image, Text } from '@geist-ui/react'
import styles from './Post.module.css'

const Post = ({ post }) => {
  return (
    <>
      <Text className={styles.postName}>{post.name}</Text>
      <Text className={styles.author}>{post.author}</Text>
      <Image
        width="100%"
        height="50%"
        alt={`Image cover for ${post.name}`}
        src={post.ogImage}
      />
      <Text className={styles.date}>{post.date}</Text>
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: post.content }}
      />
    </>
  )
}

export default Post
