import { Image } from '@geist-ui/react'

const ProductImage = ({ imageSource }) => {
  return (
    <Image
      style={{ borderRadius: 5, objectFit: 'contain' }}
      scale={1.2}
      width="100%"
      height="100%"
      src={imageSource}
      loading="lazy"
    />
  )
}

export default ProductImage
