import { Page } from '@geist-ui/react'
import { BurgerMenu } from '../../components/BurgerMenu'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import Post from '../../components/Post'
import styles from './News.module.css'
import { useParams } from 'react-router-dom'
import { posts } from '../../_posts/posts'

const News = () => {
  const { newsId } = useParams()

  const post = posts[newsId]

  return (
    <Page render="effect-seo" className={styles.pageContainer}>
      <Page.Header className={styles.header}>
        <Header />
      </Page.Header>
      <Page.Content className={styles.pageContent}>
        <BurgerMenu />

        <Post post={post} />
      </Page.Content>

      <Page.Footer
        style={{
          bottom: 'inherit',
          borderTop: 'solid 2.4px #eaeaea',
          position: 'sticky',
          background: 'white',
          zIndex: 2,
        }}
        className={styles.footerWrapper}
      >
        <Footer />
      </Page.Footer>
    </Page>
  )
}

export default News
