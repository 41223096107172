import React, { createContext, useContext, useState } from 'react'

const AppContext = createContext({
  showBurgerMenu: false,
  toggleBurgerMenuVisibility: () => {},
})

const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [showBurgerMenu, setShowBurgerMenu] = useState(false)

  const value = {
    showBurgerMenu,
    toggleBurgerMenuVisibility: () => setShowBurgerMenu((state) => !state),
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export const useBurgerMenu = () => {
  const { showBurgerMenu, toggleBurgerMenuVisibility } = useContext(AppContext)

  return { showBurgerMenu, toggleBurgerMenuVisibility }
}

export default AppProvider
