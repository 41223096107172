import { Page } from '@geist-ui/react'
import { BurgerMenu } from '../../components/BurgerMenu'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import styles from './Products.module.css'
import { useParams } from 'react-router-dom'
import products from '../../_products'
import ShowProduct from '../../components/ShowProduct'

const Products = () => {
  const { productId } = useParams()

  const product = products[productId]

  return (
    <Page render="effect-seo" className={styles.pageContainer}>
      <Page.Header className={styles.header}>
        <Header />
      </Page.Header>
      <Page.Content className={styles.pageContent}>
        <BurgerMenu />
        <ShowProduct product={product} />
      </Page.Content>

      <Page.Footer
        style={{
          bottom: 'inherit',
          borderTop: 'solid 2.4px #eaeaea',
          position: 'sticky',
          background: 'white',
          zIndex: 2,
        }}
        className={styles.footerWrapper}
      >
        <Footer />
      </Page.Footer>
    </Page>
  )
}

export default Products
