import { Page } from '@geist-ui/react'
import { useRouteError } from 'react-router-dom'
import { BurgerMenu } from '../../components/BurgerMenu'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import styles from './Error.module.css'

const AllProducts = () => {
  const error = useRouteError()
  console.error(error)

  return (
    <Page render="effect-seo" className={styles.pageContainer}>
      <Page.Header className={styles.header}>
        <Header />
      </Page.Header>
      <Page.Content className={styles.pageContent}>
        <BurgerMenu />
        <div id="error-page">
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
        </div>
      </Page.Content>

      <Page.Footer
        style={{
          bottom: 'inherit',
          borderTop: 'solid 2.4px #eaeaea',
          position: 'sticky',
          background: 'white',
          zIndex: 2,
        }}
        className={styles.footerWrapper}
      >
        <Footer />
      </Page.Footer>
    </Page>
  )
}

export default AllProducts
