import { Link } from '@geist-ui/react'
import styles from './ConservPageLink.module.css'

const ConservPageLink: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className={styles.conservPageLink}>
      <Link>{text}</Link>
    </div>
  )
}

export default ConservPageLink
