import { Text, Image, Grid } from '@geist-ui/react'
import styles from './Certifications.module.css'

const Certifications = () => {
  return (
    <div id="certifications">
      <div className={styles.certifications}>
        <Grid.Container
          className={styles.certificationContainer}
          justify="center"
        >
          <Text p b className={styles.certificationText}>
            CONSERV IS CERTIFIED
          </Text>
          <Grid.Container
            className={styles.certificationGridContainer}
            gap={0}
            justify="center"
            height="100px"
          >
            <Grid xs={6}>
              <Image
                width="60px"
                height="50px"
                src="/images/certification1.png"
              />
            </Grid>
            <Grid xs={6}>
              {' '}
              <Image
                width="60px"
                height="50px"
                src="/images/certification2.png"
              />
            </Grid>
            <Grid xs={6}>
              {' '}
              <Image
                width="60px"
                height="50px"
                src="/images/certification3.png"
              />
            </Grid>
            <Grid xs={6}>
              {' '}
              <Image
                width="100px"
                height="50px"
                src="/images/certification4.png"
              />
            </Grid>
          </Grid.Container>
        </Grid.Container>
          <Text p className={styles.aboutusText}>
            The material has been tested for food safety according to EU regulation 1935/2004 and German Food, Article of Daily Use & Feed Code LFGB 30 & 31. It is certified as suitable for direct contact with food. The performed tests are as follows:
          </Text>

          <Image
            className={styles.processImage}
            style={{ borderRadius: 20, objectFit: 'contain' }}
            src="/images/certification_list.png"
            loading="lazy"
          />
      </div>
    </div>
  )
}

export default Certifications
