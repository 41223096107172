import styles from './AboutUs.module.css'
import { Mail, PhoneCall } from '@geist-ui/icons'

const ContactCard = ({ name, mail, phone }) => {
  return (
    <>
      <div className={styles.contactCard}>
        <p className={styles.contactName}>{name}</p>
        <a
          className={styles.contactInfo}
          target={'_blank'}
          rel="noreferrer"
          href={`mailto:${mail}`}
        >
          <Mail size={20} color="grey" />
          {mail}
        </a>
        <a
          className={styles.contactInfo}
          target={'_blank'}
          rel="noreferrer"
          href={`tel:${phone}`}
        >
          <PhoneCall size={20} color="grey" />
          {phone}
        </a>
      </div>
    </>
  )
}

export default ContactCard
