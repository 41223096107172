import { Card, Input, Text, Textarea } from '@geist-ui/react'
import { useReducer, useState } from 'react'
import Button from '../Button'
import styles from './Contact.module.css'
import { isValidEmail } from '../../helpers'
import { submitContact } from '../../api'
import { Modal } from '../Modal'
import DOMPurify from 'dompurify'

type FormState = {
  userEmail: string
  userName: string
  companyName: string
  companySite: string
  message: string
}

enum FormField {
  USER_NAME = 'userName',
  USER_MAIL = 'userEmail',
  COMPANY_NAME = 'companyName',
  COMPANY_SITE = 'companySite',
  MESSAGE = 'message',
  RESET_FORM = 'reset',
}

const formReducer = (state: FormState, action) => {
  const { type, payload } = action

  switch (type) {
    case FormField.USER_NAME:
      return {
        ...state,
        userName: payload as string,
      }

    case FormField.USER_MAIL:
      return {
        ...state,
        userEmail: payload as string,
      }

    case FormField.COMPANY_NAME:
      return {
        ...state,
        companyName: payload as string,
      }

    case FormField.COMPANY_SITE:
      return {
        ...state,
        companySite: payload as string,
      }

    case FormField.MESSAGE:
      return {
        ...state,
        message: payload as string,
      }

    case FormField.RESET_FORM:
      return {
        userEmail: '',
        userName: '',
        companyName: '',
        companySite: '',
        message: '',
      }

    default:
      return state
  }
}

const Contact = () => {
  const [formState, dispatch] = useReducer(formReducer, {
    userEmail: '',
    userName: '',
    companyName: '',
    companySite: '',
    message: '',
  })

  const [userEmailError, setUserEmailError] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [showSuccessFeedback, setShowSuccessFeedback] = useState(false)
  const [showErrorFeedback, setShowErrorFeedback] = useState(false)

  return (
    <div id="contact">
      <Card shadow type="default" height="100%" className={styles.contactForm}>
        <div>
          <div className={styles.formContainer}>
            <div className={styles.formItem}>
              <Input
                width="100%"
                placeholder="john.doe@gmail.com"
                value={formState.userEmail}
                type={userEmailError ? 'error' : 'secondary'}
                onChange={(event) => {
                  setShowErrorMessage(false)

                  const mail = event.target.value

                  if (!isValidEmail(mail)) {
                    setUserEmailError(true)
                  } else {
                    setUserEmailError(false)
                  }
                  dispatch({
                    type: FormField.USER_MAIL,
                    payload: event.target.value,
                  })
                }}
              >
                YOUR EMAIL
              </Input>
            </div>

            <div className={styles.formItem}>
              <Input
                width="100%"
                placeholder="John Doe"
                value={formState.userName}
                onChange={(event) => {
                  dispatch({
                    type: FormField.USER_NAME,
                    payload: event.target.value,
                  })
                }}
              >
                YOUR NAME
              </Input>
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formItem}>
              <Input
                width="100%"
                placeholder="ConServ"
                value={formState.companyName}
                onChange={(event) => {
                  dispatch({
                    type: FormField.COMPANY_NAME,
                    payload: event.target.value,
                  })
                }}
              >
                COMPANY NAME
              </Input>
            </div>

            <div className={styles.formItem}>
              <Input
                width="100%"
                placeholder="www.conserv.se"
                value={formState.companySite}
                onChange={(event) => {
                  dispatch({
                    type: FormField.COMPANY_SITE,
                    payload: event.target.value,
                  })
                }}
              >
                COMPANY WEBSITE
              </Input>
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formItem}>
              <label className={styles.textLabel}>HOW CAN WE HELP YOU?</label>
              <Textarea
                id="contact-text"
                width="160%"
                height="150%"
                placeholder="Which product are you interested in?"
                value={formState.message}
                onChange={(event) => {
                  dispatch({
                    type: FormField.MESSAGE,
                    payload: event.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className={styles.contactFormSubmit}>
            {showErrorMessage ? (
              <Text className={styles.feedbackText}>
                {'Please provide a valid email address.'}
              </Text>
            ) : null}

            <Button
              text="Submit"
              onClick={() => {
                if (userEmailError || !Boolean(formState.userEmail)) {
                  setShowErrorMessage(true)
                } else {
                  setShowErrorMessage(false)
                  submitContact({
                    userEmail: DOMPurify.sanitize(formState.userEmail),
                    userName: DOMPurify.sanitize(formState.userName),
                    companyName: DOMPurify.sanitize(formState.companyName),
                    companySite: DOMPurify.sanitize(formState.companySite),
                    message: DOMPurify.sanitize(formState.message),
                  })
                    .then((res) => {
                      if (res.status === 200) {
                        setShowSuccessFeedback(true)
                      } else {
                        setShowErrorFeedback(true)
                      }
                    })
                    .catch((error) => {
                      console.log(error)
                      setShowErrorFeedback(true)
                    })
                  dispatch({
                    type: FormField.RESET_FORM,
                  })
                }
              }}
            />
            <Modal
              state={showSuccessFeedback}
              callback={() => setShowSuccessFeedback(false)}
              title="Thank you for your interest"
              content="We have received your request and will contact you shortly."
            />
            <Modal
              state={showErrorFeedback}
              callback={() => setShowErrorFeedback(false)}
              title="Thank you for your interest"
              content="Something unexpected happened. Please try again later."
            />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default Contact
