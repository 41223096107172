import { Text, Table } from '@geist-ui/react'
import styles from './ShowProduct.module.css'
import Button from '../Button'
import { Link } from 'react-router-dom'
import { Carousel } from '../Carousel'
import { ProductImage } from '../ProductImage'
import { DownloadProductInfo } from '../DownloadProductInfo'

const ShowProduct = ({ product }) => {
  return (
    <>
      <Text className={styles.name}>{product.name}</Text>
      <Text className={styles.description}>{product.description}</Text>
      <Carousel>
        {product.ogImage.productImages.map((imageSource) => (
          <ProductImage imageSource={imageSource} />
        ))}
      </Carousel>

      <div
        className={styles.productFeatures}
        dangerouslySetInnerHTML={{ __html: product.productFeatures }}
      />

      <Table className={styles.productData} data={product.data}>
        <Table.Column prop="property" label="property" />
        <Table.Column prop="description" label="description" />
      </Table>
    {/*  <DownloadProductInfo file={product.downloadableContent} /> */}

    <Link to={`/contact`} style={{ textDecoration: 'none' }}>
        <Button text="Enquire product" />
      </Link>
    </>
  )
}

export default ShowProduct
